import {
  GetAdminProjectQuery,
  GetProjectQuery,
  ListAdminProjectsQuery,
  ListProjectsQuery
} from 'src/config/generated/graphql'

import { ArrayElement } from './common'

// GQL types

export type ProjectType = NonNullable<
  | ArrayElement<NonNullable<GetProjectQuery['Project']>>
  | ArrayElement<NonNullable<GetAdminProjectQuery['Project']>>
>

// update this one for admin
export type AdminProjectType = NonNullable<
  ArrayElement<GetProjectQuery['Project']>
>

export type ListProjectsType = NonNullable<
  ArrayElement<ListProjectsQuery['ProjectView']>
>

export type AdminListProjectsType = NonNullable<
  ArrayElement<ListAdminProjectsQuery['ProjectView']>
>

export enum ProjectPurpose {
  CarbonSink = 'CarbonSink',
  CommunityDevelopment = 'CommunityDevelopment',
  CulturalImportance = 'CulturalImportance',
  EndangeredSpeciesHabitat = 'EndangeredSpeciesHabitat',
  EnvironmentalEducation = 'EnvironmentalEducation',
  ErosionControl = 'ErosionControl',
  EnhancingRecreationalSpaces = 'EnhancingRecreationalSpaces',
  WaterQuality = 'WaterQuality',
  WildlifeCorridor = 'WildlifeCorridor',
  BiodiversityFocus = 'BiodiversityFocus'
}

export enum LandUseOptions {
  SheepCattle = 'Sheep and beef',
  Horticulture = 'Horticulture',
  Dairy = 'Dairy',
  PublicReserve = 'Public reserve',
  Lifestyle = 'Lifestyle',
  OtherForestry = 'Forestry',
  Schools = 'School',
  Other = 'Other (please specify)'
}

export enum ProjectFeaturedOn {
  Home = 'home',
  Business = 'business',
  All = 'all'
}

export enum ProjectWorkflowState {
  Draft = 'Draft',
  Submitted = 'Submitted'
}

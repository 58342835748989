import {
  adminImportBulkGiftPath,
  adminTreePoolPath,
  adminViewApplicationPath,
  adminViewFundingPath,
  adminApplicationPlantingReportPath,
  viewRegistryPath,
  viewApplicationPath,
  adminViewAccountPath,
  adminViewAccountsMembershipsPath,
  adminViewRegistryPath,
  listMyRegistriesPath,
  applicationPlantingReportPath,
  viewProfilePath,
  adminListAccountsPath,
  adminMonitoringEventPath,
  viewBlogPostCatchAllPath,
  priceGuidePath,
  nativeTreeSpeciesPath,
  nativeTreesPath,
  adminViewFundingMatchesPath,
  adminMatchFundingPath,
  adminCreatePurchasePath,
  adminViewBulkPath,
  viewPlantingPath,
  adminViewPlantingPath,
  viewMonitoringEventPath,
  viewProjectDetailPath,
  adminViewProjectPath,
  editPlantingPath,
  adminApplicationRegistrationPath,
  adminAccountPlanterRegistrationPath,
  blogPath,
  listPlantingProjectsPath,
  viewProjectPath,
  fundingDetailPath,
  updateAccountDetailsPath,
  updateAccountBillingDetailsPath,
  viewAccountBillingDetailsPath,
  updateAccountPaymentMethodPath,
  viewAccountPaymentMethodsPath,
  updateAccountProfilePath,
  listApplicationsPath,
  adminListApplicationsPath,
  viewProjectApplicationsPath,
  adminViewProjectApplicationsPath,
  viewProjectPlantingsPath,
  adminViewProjectPlantingsPath
} from 'src/config/paths'

import { Breadcrumb } from './breadcrumbs.types'
import { AdminViewAccountBreadcrumb } from './breadcrumbs/AdminViewAccountBreadcrumb'
import { AdminViewAccountUserBreadcrumb } from './breadcrumbs/AdminViewAccountUserBreadcrumb'
import { AdminViewApplicationBreadcrumb } from './breadcrumbs/AdminViewApplicationBreadcrumb'
import { AdminViewBulkBreadcrumb } from './breadcrumbs/AdminViewBulkBreadcrumb'
import { AdminViewFundingBreadcrumb } from './breadcrumbs/AdminViewFundingBreadcrumb'
import { AdminViewPlantingBreadcrumb } from './breadcrumbs/AdminViewPlantingBreadcrumb'
import { AdminViewPlantingMonitoringBreadcrumb } from './breadcrumbs/AdminViewPlantingMonitoringBreadcrumb'
import { AdminViewProjectDetailBreadcrumb } from './breadcrumbs/AdminViewProjectDetailBreadcrumb'
import { MonitoringEventBreadcrumb } from './breadcrumbs/MonitoringEventBreadcrumb'
import { NativeTreeSpeciesBreadcrumb } from './breadcrumbs/NativeTreeSpeciesBreadcrumb'
import { ViewAccountByUriBreadcrumb } from './breadcrumbs/ViewAccountByUriBreadcrumb'
import { ViewApplicationBreadcrumb } from './breadcrumbs/ViewApplicationBreadcrumb'
import { ViewBlogPostBreadcrumb } from './breadcrumbs/ViewBlogPostBreadcrumb'
import { ViewFundingDetailBreadcrumb } from './breadcrumbs/ViewFundingDetailBreadcrumb'
import { ViewPlantingBreadcrumb } from './breadcrumbs/ViewPlantingBreadcrumb'
import { ViewPlantingMonitoringBreadcrumb } from './breadcrumbs/ViewPlantingMonitoringBreadcrumb'
import { ViewProjectDetailBreadcrumb } from './breadcrumbs/ViewProjectDetailBreadcrumb'
import { ViewPublicProjectBreadcrumb } from './breadcrumbs/ViewPublicProjectBreadcrumb'
import { ViewRegistryBreadcrumb } from './breadcrumbs/ViewRegistryBreadcrumb'
import { ViewRegistryByUriBreadcrumb } from './breadcrumbs/ViewRegistryByUriBreadcrumb'

export const customBreadcrumbs: Omit<Breadcrumb, 'href'>[] = [
  // public pages
  {
    path: viewRegistryPath(),
    breadcrumb: <ViewRegistryByUriBreadcrumb />
  },
  {
    path: viewProfilePath(),
    breadcrumb: <ViewAccountByUriBreadcrumb />
  },
  {
    path: priceGuidePath,
    breadcrumb: 'Price guide'
  },
  {
    path: nativeTreesPath,
    breadcrumb: 'Native trees'
  },
  {
    path: nativeTreeSpeciesPath(),
    breadcrumb: <NativeTreeSpeciesBreadcrumb />
  },
  {
    path: viewBlogPostCatchAllPath(),
    breadcrumb: <ViewBlogPostBreadcrumb />
  },
  {
    path: blogPath(),
    breadcrumb: 'News and stories'
  },
  // user pages
  {
    path: viewPlantingPath(),
    breadcrumb: <ViewPlantingBreadcrumb />
  },
  {
    path: viewMonitoringEventPath(),
    breadcrumb: <ViewPlantingMonitoringBreadcrumb />
  },
  {
    path: listApplicationsPath,
    breadcrumb: 'Tree requests'
  },
  {
    path: adminListApplicationsPath(),
    breadcrumb: 'Tree requests'
  },
  {
    path: viewApplicationPath(),
    breadcrumb: <ViewApplicationBreadcrumb />
  },
  {
    path: listMyRegistriesPath,
    breadcrumb: 'My registries'
  },
  {
    path: applicationPlantingReportPath(),
    breadcrumb: 'Planting report'
  },
  {
    path: updateAccountPaymentMethodPath,
    breadcrumb: 'Edit'
  },
  {
    path: viewAccountPaymentMethodsPath,
    breadcrumb: 'Edit'
  },
  // admin pages
  { path: adminViewBulkPath(), breadcrumb: <AdminViewBulkBreadcrumb /> },
  { path: adminTreePoolPath, breadcrumb: 'Tree pool' },
  { path: adminImportBulkGiftPath, breadcrumb: 'Import bulk gift' },
  {
    path: adminViewApplicationPath(),
    breadcrumb: <AdminViewApplicationBreadcrumb />
  },
  {
    path: adminApplicationPlantingReportPath(),
    breadcrumb: 'Planting report'
  },
  {
    path: adminViewFundingPath(),
    breadcrumb: <AdminViewFundingBreadcrumb />
  },
  {
    path: adminViewAccountPath(),
    breadcrumb: <AdminViewAccountBreadcrumb />
  },
  {
    path: adminViewAccountsMembershipsPath(),
    breadcrumb: <AdminViewAccountUserBreadcrumb />
  },
  {
    path: adminListAccountsPath,
    breadcrumb: 'Accounts'
  },
  {
    path: adminViewRegistryPath(),
    breadcrumb: <ViewRegistryBreadcrumb />
  },
  {
    path: adminMonitoringEventPath(),
    breadcrumb: <MonitoringEventBreadcrumb />
  },
  {
    path: adminMatchFundingPath(),
    breadcrumb: 'Match with application'
  },
  {
    path: adminViewFundingMatchesPath(),
    breadcrumb: 'View matches'
  },
  {
    path: adminCreatePurchasePath(),
    breadcrumb: 'Create purchase'
  },
  {
    path: adminViewPlantingPath(),
    breadcrumb: <AdminViewPlantingBreadcrumb />
  },
  {
    path: adminMonitoringEventPath(),
    breadcrumb: <AdminViewPlantingMonitoringBreadcrumb />
  },
  {
    path: viewProjectDetailPath(),
    breadcrumb: <ViewProjectDetailBreadcrumb />
  },
  {
    path: viewProjectPlantingsPath(),
    breadcrumb: 'Planting reports'
  },
  {
    path: adminViewProjectPlantingsPath(),
    breadcrumb: 'Planting reports'
  },
  {
    path: viewProjectApplicationsPath(),
    breadcrumb: 'Tree requests'
  },
  {
    path: adminViewProjectPath(),
    breadcrumb: <AdminViewProjectDetailBreadcrumb />
  },
  {
    path: adminViewProjectApplicationsPath(),
    breadcrumb: 'Tree requests'
  },
  {
    path: editPlantingPath(),
    breadcrumb: 'Edit'
  },
  {
    path: adminApplicationRegistrationPath(),
    breadcrumb: 'Planting experience'
  },
  {
    path: adminAccountPlanterRegistrationPath(),
    breadcrumb: 'Planting experience'
  },
  {
    path: listPlantingProjectsPath,
    breadcrumb: 'Projects'
  },
  {
    path: viewProjectPath(),
    breadcrumb: <ViewPublicProjectBreadcrumb />
  },
  {
    path: fundingDetailPath(),
    breadcrumb: <ViewFundingDetailBreadcrumb />
  },
  {
    path: updateAccountDetailsPath,
    breadcrumb: 'Edit'
  },
  {
    path: updateAccountBillingDetailsPath,
    breadcrumb: 'Edit'
  },
  {
    path: viewAccountBillingDetailsPath,
    breadcrumb: 'Payment details'
  },
  {
    path: updateAccountProfilePath,
    breadcrumb: 'Edit'
  }
]
